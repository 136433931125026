nav {
    grid-area: nav;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: fixed;
    top: 0;
    left: -100vw;
    width: calc(100vw - 0px);
    /* max-width: calc(400px - 0px); */
    color: white;
    background-color: #121212;
    box-shadow: inset 5px 0px 10px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    transition: left 0.2s;
    height: calc(100vh - 100px);
    margin-top: 100px;
}

nav ul {
    list-style: none;
    padding: 0;
}

nav ul a {
    display: block;
    color: var(--primary-color);
    padding: 1rem 1.2rem;
    text-decoration: none;
    transition: 0.2s;
}

nav ul a:hover {
    background-color: var(--gray) !important;
    color: #fff !important;
    transition: 0.2s;
}

.navActive {
    background-color: red;
    outline: 1px dotted red;
}

.logoutButton {
    padding: 1rem;
    background: red;
    color: #fff;
    width: calc(100% - 2rem);
    text-align: center;
    transition: .2s;
}

.logoutButton:hover {
    background: var(--secondary-color);
    color: var(--black);
    transition: .2s;
}


#nav-hamburger {
    display: grid;
    place-items: center;
    position: absolute;

    top: 38px;
    left: 1.2rem;

    z-index: 10;

    height: 1.2rem;
    width: 1.8rem;
}

#nav-hamburger:hover {
    cursor: pointer;
}

.navicon {
    height: 3px;
    width: 1.8rem;
    background-color: var(--dark);
    position: relative;
    display: block;
    z-index: 10;
}

.navicon:before,
.navicon:after {
  background-color: var(--dark);
  position: absolute;

  content: "";
  display: block;

  height: 3px;
  width: 100%;
  
  transition: all 0.2s ease-out;
  z-index: 10;
}

.navicon:before {
    top: -8px;
}

.navicon:after {
    top: 8px;
}

.showNavMobile {
    left: 0;
}

.naviconActive .navicon:before {
    top: 0;
    transform: rotate(45deg);
}

.naviconActive .navicon:after {
    top: 0;
    transform: rotate(135deg);
}

.naviconActive .navicon {
    background: transparent;
}

@media only screen and (min-width: 768px) {

    nav {
        position: relative;
        left: 0;
        width: calc(100% - 0px);
        max-width: none;
        margin-top: 0;
    }


    #nav-hamburger {
        display: none;
    }

}