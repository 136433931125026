@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,400&display=swap');

:root {
  /* General Palet */
  --white: #f8f8f8;
  --light: #e2e2e2;
  --dark: #020202;
  --black: #080808;

  --dark-gray: #191919;
  --gray: #343434;
  --light-gray: #a2a2a2;

  --primary-color: #FF7E07;
  --secondary-color: #FFCE54;

  --max-width: 800px;
  --global-padding: 16px;
}

html, body {
  border: 0;
  margin: 0;
  padding: 0;
  outline: 0;
  min-height: 100vh;
}

body {
  background-color: var(--dark-gray);
  color: var(--dark);
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto;
  text-align: left;
  font-family: 'Montserrat', sans-serif;
}

h1, h2, h3, h4, h5, h6 {
  color: var(--light);
}

input {
  min-width: 100px;
  font-size: 1rem;
}

select {

  font-size: 1rem;
}

input[type="checkbox"] {
  min-width: 10px;
  transform: scale(1.2);
  padding: 10px;
}