#lesson-purchase-form {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 0.5rem;
    margin-bottom: 1rem;
    align-items: center;
}

#lesson-purchase-button {
    grid-column: span 2;
}

#lesson-purchase-confirm-button {
    grid-column: span 2;
}
#lesson-purchase-cancel-button {
    grid-column: span 2;
}

.btn-primary {
    background-color: var(--primary-color);
}

@media only screen and (min-width: 1280px) {
    #lesson-purchase-form {
        grid-template-columns: auto 1fr auto 1fr;
        gap: 0.8rem;
    }
    #lesson-purchase-button {
        grid-column: 1 / 5;
    }
    #lesson-purchase-confirm-button {
        grid-column: 3 / 5;
        grid-row: 3;
    }
    #lesson-purchase-cancel-button {
        grid-column: 1 / 3;
        grid-row: 3;
    }
}
