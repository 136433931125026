.loginContainer {
    display: grid;
    place-items: center;
    min-height: 100vh;
}

.loginContainer > div {
    display: grid;
    justify-items: center;
}

.loginContainer h1 {
    color: var(--light);
}

.loginForm {
    margin: 2rem auto 0.5rem;
    display: grid;
    gap: 0.5rem;
    width: 360px;
}

.loginForm > span {
    display: none;
    color: red;
    margin: 1rem 0 0rem;
    text-align: center;
}

.login-input-label {
    margin-top: 0.6rem;
    color: var(--light);
}

.input-text {
    font-family: inherit;
    font-size: 1.2rem;
    padding: 0.8rem 1rem;
    border: none;
    border-radius: 0.6rem;
}

.btn {
    padding: .6rem 1rem;
    border: none;
    color: var(--dark);
    background-color: var(--light);
    border-radius: 0.6rem;
    cursor: pointer;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    font-size: 1rem;
    letter-spacing: 0.05rem;
    transition: 0.2s;
}

.btn:hover {
    color: var(--dark);
    background-color: var(--secondary-color);
    transition: 0.2s;
}

.btn:disabled {
    color: var(--white);
    background-color: var(--light);
    cursor: default;
}

.btn-login {
    align-self: flex-end;
    padding: 1rem 1rem;
    font-size: 1.2rem;
    font-weight: 600;
    background: var(--primary-color);
    width: 100%;
    margin: 1.6rem 0 0.8rem;
    box-shadow: 3px 5px 10px rgba(0, 0, 0, 0.2);
    transition: 0.2s;
}

.btn-login:active {
    box-shadow: none;
}

.btn-login:hover {
    background: var(--secondary-color);
    color: var(--dark);
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
    transition: 0.2s;
}

a {
    color: #ff7e07;
    text-decoration: none;
}
