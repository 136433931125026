.appContainer {
    min-height: 100vh;
    width: 100vw;
    max-width: 100vw;
    overflow-x: hidden;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr auto;
    grid-template-areas:
        "header"
        "main"
        "footer";
}

header {
    grid-area: header;
}

main {
    grid-area: main;
    color: white;
    background-color: #191919;
    padding: 1rem;
    min-height: calc(100% - 2rem);
    z-index: 1;
}

main > h1 {
    color: var(--light)
}

footer {
    grid-area: footer;
}

.scroll-container {
    max-height: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
}

.h-scroll-container {
    overflow-x: visible;
    overflow-y: hidden;
}

.v-scroll-container {
    overflow-x: hidden;
    overflow-y: visible;
}

@media only screen and (min-width: 768px) {
    .appContainer {
        max-height: 100vh;
        grid-template-columns: minmax(200px, 1fr) 3fr;
        grid-template-rows: auto 1fr;
        grid-template-areas:
            "header header"
            "nav main"
            "nav footer";
    }
}

@media only screen and (min-width: 1280px) {
    .appContainer {
        grid-template-columns: 320px 2fr;
    }
}

.ribbon-left {
    display: flex;
    justify-content: flex-start;
}
.ribbon-right {
    display: flex;
    justify-content: flex-end;
}