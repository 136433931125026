header {
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    place-items: center;
    gap: 1rem;

    height: 80px;
    padding: .5rem 4rem;

    background-color: var(--primary-color);
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);

    z-index: 2;
}

header > img {
    width: 80px;
    display: none;
}

header > h1 {
    text-transform: uppercase;
    color: var(--dark);
    font-size: 1.5rem;
    font-weight: 600;
    text-decoration: none;
    margin: auto 0;
    padding: 0;
    text-align: center;
}


@media only screen and (min-width: 768px) {

    header {
        grid-template-columns: auto 1fr;
        justify-items: start;
        padding: .5rem;
    }

    header > img {
        width: 80px;
        display: block;
    }

    header > h1 {
        text-align: left;
    }
}