.cardContainer {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    width: 100%;
    text-align: center;
    margin-bottom: 1rem;
}

.card {
    border-radius: .3rem;
    background-color: var(--gray);
    color: var(--light-gray);
    box-shadow: 3px 5px 10px rgba(0,0,0,.1);
    border: 1px solid rgba(0,0,0,.3);
    width: 100%;
    max-width: 600px;
    padding: 16px 0;
}

.card > h2 {
    color: var(--light);
    margin: .6rem 0 1rem;
}

.card > p {
    margin: .4rem 0;
    font-size: 1.2rem;
}