.homePage > h2 {
    margin: 1.5rem 0 1rem 0;
}

#attendanceForm {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: .5rem 2rem;
    margin-bottom: 2rem;
}

#attendanceForm > button {
    grid-column: span 2;
    margin-top: .5rem;
}

#attendance-payment-form {
    display: grid;
    place-items: center;
    gap: 1rem;
    padding: 10px 0 30px 0;
}

#attendance-payment-form > button {
    min-width: 300px;
    padding: .2rem;
}

#attendance-confirm-payment {
    display: grid;
    place-items: center;
    gap: 1rem;
    margin: 0;
    padding: 0;
    /* max-width: 400px; */
}
#attendance-confirm-payment > p {
    margin: 0;
    padding: 0;
}

#attendance-confirm-payment > button {
    min-width: 300px;
}

@media only screen and (min-width: 1280px) {
    #attendanceForm {
        grid-template-columns: auto 1fr auto 1fr;
        align-items: center;
    }
    #attendanceForm > button {
        grid-column: span 4;
        margin: 0;
    }
}